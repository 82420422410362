@import './includes';

html[data-theme='dark'] {
  --kp-color-bg-base: var(--kp-color-dark-neutral-900);
}

body[data-theme='dark'] {
  --kp-color-bg-alert: var(--kp-color-red-900);
  --kp-color-bg-alert-prom: var(--kp-color-red-800);
  --kp-color-bg-base: var(--kp-color-dark-neutral-900);
  --kp-color-bg-ecomm: var(--kp-color-yellow-900);
  --kp-color-bg-edu: var(--kp-color-teal-800);
  --kp-color-bg-info: var(--kp-color-blue-900);
  --kp-color-bg-info-prom: var(--kp-color-blue-800);
  --kp-color-bg-primary: var(--kp-color-dark-neutral-700);
  --kp-color-bg-secondary: var(--kp-color-dark-neutral-600);
  --kp-color-bg-success: var(--kp-color-teal-900);
  --kp-color-bg-success-prom: var(--kp-color-teal-800);
  --kp-color-bg-tertiary: var(--kp-color-dark-navy-700);
  --kp-color-bg-warning: var(--kp-color-yellow-900);
  --kp-color-bg-warning-prom: var(--kp-color-yellow-800);
  --kp-color-bg-ZO: var(--kp-color-yellow-900);
  --kp-color-bg-ZO-gradient-1: var(--kp-color-brown-900);
  --kp-color-bg-ZO-gradient-2: var(--kp-color-brown-700);

  --kp-color-border-accent: var(--kp-color-blue-300);
  --kp-color-border-alert: var(--kp-color-red-700);
  --kp-color-border-default: var(--kp-color-dark-neutral-500);
  --kp-color-border-info: var(--kp-color-blue-700);
  --kp-color-border-interactive-primary: var(--kp-color-blue-300);
  --kp-color-border-interactive-secondary: var(--kp-color-navy-blue-500);
  --kp-color-border-neutral: var(--kp-color-neutral-black);
  --kp-color-border-neutral-prom: var(--kp-color-grayscale-600);
  --kp-color-border-positive: var(--kp-color-green-800);
  --kp-color-border-success: var(--kp-color-teal-800);
  --kp-color-border-warning: var(--kp-color-yellow-800);
  --kp-color-border-ZO: var(--kp-color-yellow-800);

  --kp-color-brand-fill-blue: var(--kp-color-blue-500-brand);
  --kp-color-brand-fill-green: var(--kp-color-green-500-brand);
  --kp-color-brand-fill-red: var(--kp-color-red-500-brand);
  --kp-color-brand-fill-yellow: var(--kp-color-yellow-500-brand);
  --kp-color-brand-logo-blue: var(--kp-color-grayscale-100);
  --kp-color-brand-logo-green: var(--kp-color-grayscale-100);
  --kp-color-brand-logo-navy-blue: var(--kp-color-grayscale-100);
  --kp-color-brand-logo-red: var(--kp-color-grayscale-100);
  --kp-color-brand-logo-yellow: var(--kp-color-grayscale-100);
  --kp-color-brand-monogram-navy-blue: var(--kp-color-neutral-white);
  --kp-color-brand-monogram-red: var(--kp-color-neutral-white);

  --kp-color-content-accent-primary: var(--kp-color-blue-300);
  --kp-color-content-accent-secondary: var(--kp-color-navy-blue-300);
  --kp-color-content-akcijski: var(--kp-color-red-200);
  --kp-color-content-alert: var(--kp-color-red-400);
  --kp-color-content-dim: var(--kp-color-dark-navy-500);
  --kp-color-content-ecomm-onFill: var(--kp-color-navy-blue-700-brand);
  --kp-color-content-info: var(--kp-color-blue-300);
  --kp-color-content-interactive-primary: var(--kp-color-blue-300);
  --kp-color-content-interactive-secondary: var(--kp-color-navy-blue-400);
  --kp-color-content-onFill: var(--kp-color-neutral-white);
  --kp-color-content-positive: var(--kp-color-green-400);
  --kp-color-content-poslovi: var(--kp-color-green-500-brand);
  --kp-color-content-price: var(--kp-color-red-400);
  --kp-color-content-regular-primary: var(--kp-color-grayscale-100);
  --kp-color-content-regular-secondary: var(--kp-color-grayscale-400);
  --kp-color-content-regular-tertiary: var(--kp-color-grayscale-500);
  --kp-color-content-success: var(--kp-color-teal-100);
  --kp-color-content-warning: var(--kp-color-yellow-300);
  --kp-color-content-warning-accent: var(--kp-color-yellow-500-brand);
  --kp-color-content-ZO: var(--kp-color-brown-500);

  --kp-color-disabled-dim: var(--kp-color-grayscale-600);
  --kp-color-disabled-prom: var(--kp-color-grayscale-500);

  --kp-color-fill-accent: var(--kp-color-blue-600);
  --kp-color-fill-accent-promo: var(--kp-color-blue-400);
  --kp-color-fill-akcijski-prom: var(--kp-color-red-700);
  --kp-color-fill-alert: var(--kp-color-red-600);
  --kp-color-fill-alert-hover: var(--kp-color-red-500-brand);
  --kp-color-fill-disabled: var(--kp-color-grayscale-700);
  --kp-color-fill-ecomm: var(--kp-color-yellow-500-brand);
  --kp-color-fill-ecomm-hover: var(--kp-color-yellow-400);
  --kp-color-fill-interactive-primary: var(--kp-color-blue-600);
  --kp-color-fill-interactive-primary-hover: var(--kp-color-blue-500-brand);
  --kp-color-fill-interactive-secondary: var(--kp-color-dark-navy-600);
  --kp-color-fill-interactive-secondary-hover: var(--kp-color-dark-navy-500);
  --kp-color-fill-neutral: var(--kp-color-dark-neutral-700);
  --kp-color-fill-neutral-dim: var(--kp-color-dark-neutral-600);
  --kp-color-fill-neutral-inverted: var(--kp-color-neutral-white);
  --kp-color-fill-positive: var(--kp-color-green-600);
  --kp-color-fill-poslovi: var(--kp-color-green-500-brand);
  --kp-color-fill-poslovi-hover: var(--kp-color-green-400);
  --kp-color-fill-skeleton-dim: var(--kp-color-dark-neutral-800);
  --kp-color-fill-skeleton-prom: var(--kp-color-dark-neutral-600);
  --kp-color-fill-success: var(--kp-color-teal-500);
  --kp-color-fill-warning: var(--kp-color-yellow-400);
  --kp-color-fill-warning-prom: var(--kp-color-yellow-700);
  --kp-color-fill-ZO: var(--kp-color-brown-600);
  --kp-color-fill-ZO-dim: #2c2407;
  --kp-color-fill-ZO-hover: var(--kp-color-brown-500);

  --kp-color-form-bg-active: var(--kp-color-blue-500-brand);
  --kp-color-form-bg-control: var(--kp-color-dark-neutral-700);
  --kp-color-form-bg-control-hover: var(--kp-color-dark-navy-800);
  --kp-color-form-bg-disabled: var(--kp-color-dark-neutral-700);
  --kp-color-form-bg-input: var(--kp-color-dark-neutral-600);
  --kp-color-form-bg-selected: var(--kp-color-dark-navy-600);
  --kp-color-form-bg-selected-alt: var(--kp-color-dark-navy-700);
  --kp-color-form-border-active: var(--kp-color-blue-500-brand);
  --kp-color-form-border-default: var(--kp-color-dark-navy-500);
  --kp-color-form-border-disabled: var(--kp-color-grayscale-700);
  --kp-color-form-border-error: var(--kp-color-red-400);
  --kp-color-form-border-hover: var(--kp-color-dark-navy-600);

  --kp-color-custom-dexpress-black: var(--kp-color-grayscale-100);
  --kp-color-custom-dexpress-red: #ff0000;
  --kp-color-custom-visa-icon: #ffffff;
  --kp-color-custom-dinacard-red: #ffffff;
  --kp-color-custom-dinacard-blue: #ffffff;

  --kp-color-transparent-neutral-10: var(--kp-color-transparent-black-a10);
  --kp-color-transparent-neutral-20: var(--kp-color-transparent-black-a20);
  --kp-color-transparent-neutral-30: var(--kp-color-transparent-black-a30);
  --kp-color-transparent-neutral-40: var(--kp-color-transparent-black-a40);
  --kp-color-transparent-neutral-50: var(--kp-color-transparent-black-a50);
  --kp-color-transparent-neutral-60: var(--kp-color-transparent-black-a60);
  --kp-color-transparent-neutral-70: var(--kp-color-transparent-black-a70);
  --kp-color-transparent-neutral-80: var(--kp-color-transparent-black-a80);
  --kp-color-transparent-neutral-90: var(--kp-color-transparent-black-a90);

  --kp-color-transparent-overlay-desktop: rgba(56, 67, 79, 0.8);
  --kp-color-transparent-overlay-mobile: rgba(73, 86, 100, 0.8);
  --kp-color-transparent-overlay-interactive: rgba(25, 43, 63, 0.8);

  --kp-color-scroll-bg: var(--kp-color-neutral-black);
  --kp-color-scroll-bar: var(--kp-color-grayscale-600);

  // img {
  //   filter: brightness(0.8) contrast(1.2);
  // }
}
