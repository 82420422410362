@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.ldsRing {
  // display: inline-block;
  // position: relative;
  // right: 2rem;
  // top: 25%;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.2rem solid var(--kp-color-content-onFill);
  border-radius: 50%;
  animation: ldsRing 1.2s $animation-function-3 infinite;
  border-color: var(--kp-color-content-onFill) transparent transparent
    transparent;
}

.blue div {
  border-color: var(--kp-color-content-interactive-primary) transparent
    transparent transparent;
}

.darkBlue div {
  border-color: var(--kp-color-content-interactive-secondary) transparent
    transparent transparent;
}

.red div {
  border-color: var(--kp-color-content-alert) transparent transparent
    transparent;
}

.ecomm div {
  border-color: var(--kp-color-content-ecomm-onFill) transparent transparent
    transparent;
}

.medium div {
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
