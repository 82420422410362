@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.primaryBlue {
  padding: 0.8rem 1.6rem;
  border-radius: 0.2rem;
  background-color: var(--kp-color-fill-interactive-primary);
  color: var(--kp-color-content-onFill);
  box-shadow: var(--shadow-3);

  &.small {
    padding: 0.4rem 0.8rem;
  }

  @include respond-below(sm) {
    font-weight: bold;
    border-radius: 0.4rem;
  }
}

.primaryBlue:visited,
.primaryBlue:link {
  color: var(--kp-color-content-onFill);
}

//display default hover state
.primaryBlue:hover {
  background-color: var(--kp-color-fill-interactive-primary-hover);
}

.primaryBlue:disabled,
.primaryBlue.isDisabled {
  background-color: var(--kp-color-fill-disabled);
  color: var(--kp-color-disabled-dim);
}
