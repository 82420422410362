:root {
  --kp-color-neutral-black: #000000;
  --kp-color-neutral-white: #ffffff;

  --kp-color-navy-blue-100: #f4f6f8;
  --kp-color-navy-blue-200: #e0e7ef;
  --kp-color-navy-blue-300: #ccd6e1;
  --kp-color-navy-blue-400: #b3c2d2;
  --kp-color-navy-blue-500: #8099b4;
  --kp-color-navy-blue-600: #335c86;
  --kp-color-navy-blue-700-brand: #003368;
  --kp-color-navy-blue-800: #002953;
  --kp-color-navy-blue-900: #001429;

  --kp-color-blue-100: #e3f3fc;
  --kp-color-blue-200: #b9e1f8;
  --kp-color-blue-300: #84caf0;
  --kp-color-blue-400: #259ada;
  --kp-color-blue-500-brand: #007ec3;
  --kp-color-blue-600: #00679e;
  --kp-color-blue-700: #005685;
  --kp-color-blue-800: #003f61;
  --kp-color-blue-900: #00283D;

  --kp-color-red-100: #ffedeb;
  --kp-color-red-200: #ffd2cc;
  --kp-color-red-300: #ffa599;
  --kp-color-red-400: #ff624d;
  --kp-color-red-500-brand: #ff1e00;
  --kp-color-red-600: #cc1800;
  --kp-color-red-700: #991200;
  --kp-color-red-800: #570A00;
  --kp-color-red-900: #290500;

  --kp-color-green-100: #eff7e4;
  --kp-color-green-200: #dcedc4;
  --kp-color-green-300: #c9e4a5;
  --kp-color-green-400: #add973;
  --kp-color-green-500-brand: #8cc63f;
  --kp-color-green-600: #73a531;
  --kp-color-green-700: #527623;
  --kp-color-green-800: #2e4314;
  --kp-color-green-900: #19230b;

  --kp-color-teal-100: #d9f2eb;
  --kp-color-teal-200: #9fdece;
  --kp-color-teal-300: #70cdb5;
  --kp-color-teal-400: #40bd9d;
  --kp-color-teal-500: #10ac84;
  --kp-color-teal-600: #0d8a6a;
  --kp-color-teal-700: #0a674f;
  --kp-color-teal-800: #07503d;
  --kp-color-teal-900: #03251D;

  --kp-color-yellow-100: #fff8db;
  --kp-color-yellow-200: #ffeb99;
  --kp-color-yellow-300: #ffe166;
  --kp-color-yellow-400: #ffdc4d;
  --kp-color-yellow-500-brand: #ffcd00;
  --kp-color-yellow-600: #c6a006;
  --kp-color-yellow-700: #8e740b;
  --kp-color-yellow-800: #594a0d;
  --kp-color-yellow-900: #2C2407;

  --kp-color-brown-100: #f2eadd;
  --kp-color-brown-200: #e6d6ba;
  --kp-color-brown-300: #d9c198;
  --kp-color-brown-400: #cdad75;
  --kp-color-brown-500: #c09853;
  --kp-color-brown-600: #a27c3a;
  --kp-color-brown-700: #7f612e;
  --kp-color-brown-800: #4f3e1f;
  --kp-color-brown-900: #211A0D;

  --kp-color-grayscale-100: #f4f4f4;
  --kp-color-grayscale-200: #e6e6e6;
  --kp-color-grayscale-300: #cccccc;
  --kp-color-grayscale-400: #b3b3b3;
  --kp-color-grayscale-500: #999999;
  --kp-color-grayscale-600: #666666;
  --kp-color-grayscale-700: #333333;
  --kp-color-grayscale-800: #262626;
  --kp-color-grayscale-900: #171717;

  --kp-color-dark-navy-500: #3e445b;
  --kp-color-dark-navy-600: #313649;
  --kp-color-dark-navy-700: #252937;
  --kp-color-dark-navy-800: #191b24;
  --kp-color-dark-navy-900: #111218;

  --kp-color-dark-neutral-500: #31383F;
  --kp-color-dark-neutral-600: #282E34;
  --kp-color-dark-neutral-700: #1b1f23;
  --kp-color-dark-neutral-800: #131619;
  --kp-color-dark-neutral-900: #0d0f11;

  --kp-color-transparent-navy-blue-a60: rgba(11, 47, 85, 0.6);
  --kp-color-transparent-navy-blue-a90: rgba(11, 47, 85, 0.9);

  --kp-color-transparent-white-a10: rgba(255, 255, 255, 0.1);
  --kp-color-transparent-white-a20: rgba(255, 255, 255, 0.2);
  --kp-color-transparent-white-a30: rgba(255, 255, 255, 0.3);
  --kp-color-transparent-white-a40: rgba(255, 255, 255, 0.4);
  --kp-color-transparent-white-a50: rgba(255, 255, 255, 0.5);
  --kp-color-transparent-white-a60: rgba(255, 255, 255, 0.6);
  --kp-color-transparent-white-a70: rgba(255, 255, 255, 0.7);
  --kp-color-transparent-white-a80: rgba(255, 255, 255, 0.8);
  --kp-color-transparent-white-a90: rgba(255, 255, 255, 0.9);

  --kp-color-transparent-black-a10: rgba(0, 0, 0, 0.1);
  --kp-color-transparent-black-a20: rgba(0, 0, 0, 0.2);
  --kp-color-transparent-black-a30: rgba(0, 0, 0, 0.3);
  --kp-color-transparent-black-a40: rgba(0, 0, 0, 0.4);
  --kp-color-transparent-black-a50: rgba(0, 0, 0, 0.5);
  --kp-color-transparent-black-a60: rgba(0, 0, 0, 0.6);
  --kp-color-transparent-black-a70: rgba(0, 0, 0, 0.7);
  --kp-color-transparent-black-a80: rgba(0, 0, 0, 0.8);
  --kp-color-transparent-black-a90: rgba(0, 0, 0, 0.9);
}
