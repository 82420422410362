:root {
  --kp-color-bg-alert: var(--kp-color-red-100);
  --kp-color-bg-alert-prom: #ffe2df;
  --kp-color-bg-base: var(--kp-color-grayscale-100);
  --kp-color-bg-ecomm: var(--kp-color-yellow-100);
  --kp-color-bg-edu: var(--kp-color-teal-600);
  --kp-color-bg-info: var(--kp-color-blue-100);
  --kp-color-bg-info-prom: #d1ebfa;
  --kp-color-bg-primary: var(--kp-color-neutral-white);
  --kp-color-bg-secondary: var(--kp-color-navy-blue-100);
  --kp-color-bg-success: var(--kp-color-teal-100);
  --kp-color-bg-success-prom: #c6ebe1;
  --kp-color-bg-tertiary: var(--kp-color-navy-blue-200);
  --kp-color-bg-warning: var(--kp-color-yellow-100);
  --kp-color-bg-warning-prom: #fff0b5;
  --kp-color-bg-ZO: var(--kp-color-yellow-100);
  --kp-color-bg-ZO-gradient-1: #fef4ca;
  --kp-color-bg-ZO-gradient-2: #f5e0a7;

  --kp-color-border-accent: var(--kp-color-blue-500-brand);
  --kp-color-border-alert: var(--kp-color-red-500-brand);
  --kp-color-border-default: var(--kp-color-navy-blue-200);
  --kp-color-border-info: var(--kp-color-blue-500-brand);
  --kp-color-border-interactive-primary: var(--kp-color-blue-500-brand);
  --kp-color-border-interactive-secondary: var(--kp-color-navy-blue-700-brand);
  --kp-color-border-neutral: var(--kp-color-neutral-white);
  --kp-color-border-neutral-prom: var(--kp-color-grayscale-500);
  --kp-color-border-positive: var(--kp-color-green-600);
  --kp-color-border-success: var(--kp-color-teal-600);
  --kp-color-border-warning: var(--kp-color-yellow-700);
  --kp-color-border-ZO: #f2d484;

  --kp-color-brand-fill-blue: var(--kp-color-blue-500-brand);
  --kp-color-brand-fill-green: var(--kp-color-green-500-brand);
  --kp-color-brand-fill-red: var(--kp-color-red-500-brand);
  --kp-color-brand-fill-yellow: var(--kp-color-yellow-500-brand);
  --kp-color-brand-logo-blue: var(--kp-color-blue-500-brand);
  --kp-color-brand-logo-green: var(--kp-color-green-500-brand);
  --kp-color-brand-logo-navy-blue: var(--kp-color-navy-blue-700-brand);
  --kp-color-brand-logo-red: var(--kp-color-red-500-brand);
  --kp-color-brand-logo-yellow: var(--kp-color-yellow-500-brand);
  --kp-color-brand-monogram-navy-blue: var(--kp-color-navy-blue-700-brand);
  --kp-color-brand-monogram-red: var(--kp-color-red-500-brand);

  --kp-color-content-accent-primary: var(--kp-color-blue-500-brand);
  --kp-color-content-accent-secondary: var(--kp-color-navy-blue-700-brand);
  --kp-color-content-akcijski: var(--kp-color-red-500-brand);
  --kp-color-content-alert: var(--kp-color-red-500-brand);
  --kp-color-content-dim: var(--kp-color-navy-blue-300);
  --kp-color-content-ecomm-onFill: var(--kp-color-navy-blue-700-brand);
  --kp-color-content-info: var(--kp-color-blue-500-brand);
  --kp-color-content-interactive-primary: var(--kp-color-blue-500-brand);
  --kp-color-content-interactive-secondary: var(--kp-color-navy-blue-700-brand);
  --kp-color-content-onFill: var(--kp-color-neutral-white);
  --kp-color-content-positive: var(--kp-color-green-600);
  --kp-color-content-poslovi: var(--kp-color-green-500-brand);
  --kp-color-content-price: var(--kp-color-red-600);
  --kp-color-content-regular-primary: var(--kp-color-grayscale-900);
  --kp-color-content-regular-secondary: var(--kp-color-grayscale-600);
  --kp-color-content-regular-tertiary: var(--kp-color-grayscale-500);
  --kp-color-content-success: var(--kp-color-teal-800);
  --kp-color-content-warning: var(--kp-color-yellow-700);
  --kp-color-content-warning-accent: var(--kp-color-yellow-500-brand);
  --kp-color-content-ZO: var(--kp-color-brown-500);

  --kp-color-disabled-dim: var(--kp-color-grayscale-300);
  --kp-color-disabled-prom: var(--kp-color-grayscale-500);

  --kp-color-fill-accent: var(--kp-color-blue-500-brand);
  --kp-color-fill-accent-promo: var(--kp-color-blue-500-brand);
  --kp-color-fill-akcijski-prom: var(--kp-color-red-200);
  --kp-color-fill-alert: var(--kp-color-red-500-brand);
  --kp-color-fill-alert-hover: var(--kp-color-red-600);
  --kp-color-fill-disabled: var(--kp-color-grayscale-200);
  --kp-color-fill-ecomm: var(--kp-color-yellow-500-brand);
  --kp-color-fill-ecomm-hover: var(--kp-color-yellow-600);
  --kp-color-fill-interactive-primary: var(--kp-color-blue-500-brand);
  --kp-color-fill-interactive-primary-hover: var(--kp-color-blue-600);
  --kp-color-fill-interactive-secondary: var(--kp-color-blue-100);
  --kp-color-fill-interactive-secondary-hover: var(--kp-color-blue-200);
  --kp-color-fill-neutral: var(--kp-color-neutral-white);
  --kp-color-fill-neutral-dim: var(--kp-color-navy-blue-100);
  --kp-color-fill-neutral-inverted: var(--kp-color-neutral-black);
  --kp-color-fill-positive: var(--kp-color-green-600);
  --kp-color-fill-poslovi: var(--kp-color-green-500-brand);
  --kp-color-fill-poslovi-hover: var(--kp-color-green-600);
  --kp-color-fill-skeleton-dim: var(--kp-color-navy-blue-300);
  --kp-color-fill-skeleton-prom: var(--kp-color-navy-blue-100);
  --kp-color-fill-success: var(--kp-color-teal-600);
  --kp-color-fill-warning: var(--kp-color-yellow-700);
  --kp-color-fill-warning-prom: var(--kp-color-yellow-500-brand);
  --kp-color-fill-ZO: var(--kp-color-brown-500);
  --kp-color-fill-ZO-dim: #f4e9ca;
  --kp-color-fill-ZO-hover: var(--kp-color-brown-600);

  --kp-color-form-bg-active: var(--kp-color-blue-500-brand);
  --kp-color-form-bg-control: var(--kp-color-neutral-white);
  --kp-color-form-bg-control-hover: var(--kp-color-navy-blue-100);
  --kp-color-form-bg-disabled: var(--kp-color-neutral-white);
  --kp-color-form-bg-input: var(--kp-color-neutral-white);
  --kp-color-form-bg-selected: var(--kp-color-blue-100);
  --kp-color-form-bg-selected-alt: var(--kp-color-navy-blue-200);
  --kp-color-form-border-active: var(--kp-color-blue-500-brand);
  --kp-color-form-border-default: var(--kp-color-navy-blue-300);
  --kp-color-form-border-disabled: var(--kp-color-grayscale-200);
  --kp-color-form-border-error: var(--kp-color-red-500-brand);
  --kp-color-form-border-hover: var(--kp-color-navy-blue-400);

  --kp-color-custom-dexpress-black: #000000;
  --kp-color-custom-dexpress-red: #ff0000;
  --kp-color-custom-visa-icon: #1a1f70;
  --kp-color-custom-dinacard-red: #c52f33;
  --kp-color-custom-dinacard-blue: #064b76;

  --kp-color-transparent-neutral-10: var(--kp-color-transparent-white-a10);
  --kp-color-transparent-neutral-20: var(--kp-color-transparent-white-a20);
  --kp-color-transparent-neutral-30: var(--kp-color-transparent-white-a30);
  --kp-color-transparent-neutral-40: var(--kp-color-transparent-white-a40);
  --kp-color-transparent-neutral-50: var(--kp-color-transparent-white-a50);
  --kp-color-transparent-neutral-60: var(--kp-color-transparent-white-a60);
  --kp-color-transparent-neutral-70: var(--kp-color-transparent-white-a70);
  --kp-color-transparent-neutral-80: var(--kp-color-transparent-white-a80);
  --kp-color-transparent-neutral-90: var(--kp-color-transparent-white-a90);

  --kp-color-transparent-overlay-desktop: var(
    --kp-color-transparent-navy-blue-a60
  );

  --kp-color-transparent-overlay-mobile: var(
    --kp-color-transparent-navy-blue-a60
  );
  --kp-color-transparent-overlay-interactive: var(
    --kp-color-transparent-navy-blue-a90
  );

  --kp-color-scroll-bg: var(--kp-color-grayscale-100);
  --kp-color-scroll-bar: var(--kp-color-grayscale-400);
}
