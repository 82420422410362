@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.link {
  color: var(--kp-color-content-interactive-secondary);
  display: inline-flex;
  align-items: center;

  .leading,
  .trailing {
    display: flex;
  }

  .leading {
    margin-right: 0.4rem;
  }

  .trailing {
    margin-left: 0.4rem;
  }

  &:visited,
  &:link {
    color: var(--kp-color-content-interactive-secondary);
  }

  &:focus {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.primary {
    color: var(--kp-color-content-interactive-primary);

    &:visited,
    &:link {
      color: var(--kp-color-content-interactive-primary);
    }
  }

  &.inherit {
    color: inherit;
    &:focus {
      text-decoration: none;
    }

    &.isDisabled {
      opacity: 1;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.isDisabled {
    text-decoration: none !important;
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.3;
  }
}
