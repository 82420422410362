// Super Form Reset
// A couple of things to watch out for:
// - IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
// - The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
// - You NEED to set the font-size and family on all form elements
// - Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
// - You can style the upload button in webkit using ::-webkit-file-upload-button
// - ::-webkit-file-upload-button smallBusinessSelectors can't be used in the same selector as normal ones. FF and IE freak out.
// - IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
// - By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs
// ----------------------------------------------------------------------------------------------------*/

@import './includes';
@import './font-face';

:root {
  --color-primary-black: #{$color-primary-black};
  --color-primary-white: #{$color-primary-white};
  --color-primary-navy-blue: #{$color-primary-navy-blue};
  --color-primary-sky-blue: #{$color-primary-sky-blue};
  --color-primary-action-red: #{$color-primary-action-red};
  --color-primary-gray: #{$color-primary-gray};
  --color-secondary-price-red: #{$color-secondary-price-red};
  --color-secondary-text-red: #{$color-secondary-text-red};
  --color-secondary-elm-red: #{$color-secondary-elm-red};
  --color-secondary-bg-red: #{$color-secondary-bg-red};
  --color-secondary-lime-green: #{$color-secondary-lime-green};
  --color-secondary-lime-green-1: #{$color-secondary-lime-green-1};
  --color-secondary-text-green: #{$color-secondary-text-green};
  --color-secondary-elm-green: #{$color-secondary-elm-green};
  --color-secondary-bg-green: #{$color-secondary-bg-green};
  --color-secondary-lemon-yellow: #{$color-secondary-lemon-yellow};
  --color-secondary-text-brown: #{$color-secondary-text-brown};
  --color-secondary-elm-brown: #{$color-secondary-elm-brown};
  --color-secondary-bg-brown: #{$color-secondary-bg-brown};
  --color-secondary-gold: #{$color-secondary-gold};
  --color-secondary-text-blue: #{$color-secondary-text-blue};
  --color-secondary-elm-blue: #{$color-secondary-elm-blue};
  --color-secondary-bg-blue: #{$color-secondary-bg-blue};
  --color-grayscale-1: #{$color-grayscale-1};
  --color-grayscale-2: #{$color-grayscale-2};
  --color-grayscale-3: #{$color-grayscale-3};
  --color-grayscale-4: #{$color-grayscale-4};
  --color-grayscale-elm-1: #{$color-grayscale-elm-1};
  --color-grayscale-elm-2: #{$color-grayscale-elm-2};

  --shadow-1: #{$shadow-1};
  --shadow-2: #{$shadow-2};
  --shadow-3: #{$shadow-3};
  --shadow-4: #{$shadow-4};

  --inner-shadow-1: #{$inner-shadow-1};
  --inner-shadow-2: #{$inner-shadow-2};
  --inner-shadow-3: #{$inner-shadow-3};
  --inner-shadow-4: #{$inner-shadow-4};
}

html {
  background: var(--kp-color-bg-base);
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
  width: 100vw;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 2rem;

  /* Browsers have different default form fonts */
  font-size: $font-size-small;
  font-family: 'PT Sans', sans-serif;
  color: var(--kp-color-content-regular-primary);
  -webkit-appearance: none;
}

label {
  color: var(--kp-color-content-regular-primary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px var(--kp-color-form-bg-selected) inset !important;
  -webkit-text-fill-color: var(--kp-color-content-regular-primary) !important;
  box-shadow: 0 0 0px 1000px var(--kp-color-form-bg-selected) inset !important;
  text-fill-color: var(--kp-color-content-regular-primary) !important;
}

/* Remove outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: border-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select {
  box-sizing: border-box;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/* Text Inputs
-----------------------------------------------*/

/* Button Controls
-----------------------------------------------*/

input[type='checkbox'],
input[type='radio'] {
  width: 1.3rem;
  height: 1.3rem;
}

/* File Uploads
-----------------------------------------------*/

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type='search'] {
  box-sizing: border-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type='reset'],
input[type='button'],
input[type='submit'] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
  white-space: break-spaces;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

/* ScrollBar
-----------------------------------------------*/

::-webkit-scrollbar {
  width: 1.2rem;

  @include respond-below(sm) {
    width: 0.5rem;
  }
}

::-webkit-scrollbar-track {
  background: var(--kp-color-bg-primary);
}
::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background-color: var(--kp-color-content-regular-tertiary);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: 'PT Sans', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: $font-size-small;
  color: var(--kp-color-content-regular-primary);
  background-color: var(--kp-color-bg-base);
  overflow-x: hidden;

  @include respond-below(sm) {
    min-height: 100%;
  }

  @media (max-width: 1008px) {
    overflow-x: auto;
  }
}

html,
body,
body > div:first-child,
div#__next {
  @include respond-below(sm) {
    height: 100%;
  }
}

body.kp-overflow-hidden {
  overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

li {
  line-height: 2rem;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  display: inline-block;
}

p {
  font-size: $font-size-small;
  line-height: 2rem;
}

button {
  outline: none;
}

[role='button']:focus,
button:focus {
  @include respond-above(md) {
    outline: none;
  }
}

.public-DraftEditor-content .public-DraftStyleDefault-block {
  margin: 0;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
