@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.darkSwitch {
  position: fixed;
  bottom: 3rem;
  left: 2rem;
  z-index: 1000;

  @include respond-below(sm) {
    left: unset;
    bottom: unset;
    right: 5.6rem;
    top: 0.8rem;
  }
}
