@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: var(--kp-color-neutral-white);
}
.container * {
  box-sizing: border-box;
}
.content {
  position: relative;
  width: 100%;
  max-width: 82rem;
  margin: 0 auto;
  z-index: 10;
  padding: 0 1rem;
  overflow: hidden;
}
.content h1 {
  font-size: 2em;
  font-weight: bold;
  color: var(--kp-color-green-500-brand);
  text-align: center;
  margin-top: 4rem;
}
.content > h2 {
  font-size: $font-size-large;
  font-weight: bold;
  color: var(--kp-color-grayscale-600);
  text-align: center;
}
.logo {
  display: block;
  padding-top: 10%;
  padding-bottom: 4.6rem;
  width: 19.2rem;
  margin: 0 auto;
  @include respond-below(sm) {
    padding: 4rem 0 2.4rem 0;
  }
}

.animationHolder {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  @include respond-below(sm) {
    margin: 0;
  }
}
.gifAnimation {
  width: 100%;
  text-align: center;
}
.gifAnimation > img {
  width: 100%;
}
.gifAnimation#animation1 {
  z-index: 2;
}
.gifAnimation#animation2 {
  z-index: 1;
}
.centerAlign {
  text-align: center;
}
p.mainText {
  font-size: 2.2rem;
  line-height: 3.4rem;
  color: var(--kp-color-navy-blue-700-brand);
  margin: 0;
  @include respond-below(sm) {
    margin-bottom: 2.4rem;
  }
}
.mainText .large {
  font-size: 2.8rem;
  @include respond-below(sm) {
    font-size: $font-size-x-large;
    color: var(--kp-color-neutral-black);
    font-weight: 400;
    line-height: 2.6rem;
  }
}
.mainText .small {
  font-size: $font-size-regular;
  @include respond-below(sm) {
    color: var(--kp-color-neutral-black);
    font-weight: 400;
    line-height: 2.1rem;
  }
}
.noselect {
  user-select: none;
}

.small {
  > span {
    display: inline;
    font-size: inherit;
    font-weight: bold;
  }

  > span:nth-of-type(1) {
    color: var(--kp-color-red-500-brand);
  }

  > span:nth-of-type(2) {
    color: var(--kp-color-navy-blue-700-brand);
  }
}

.title {
  font-size: $font-size-x-large;
  line-height: 2.4rem;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  margin-top: 1.8rem;
  color: var(--kp-color-neutral-black);
}

.description {
  font-size: $font-size-regular;
  line-height: 2.4rem;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-top: 0.4rem;
  color: var(--kp-color-neutral-black);
}

.appStoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.4rem;
  margin-bottom: 8rem;
  margin-top: 4rem;
}
