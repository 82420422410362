/**
  * @tokens FontSize
  * @presenter FontSize
  */

$font-size-small: 1.4rem;
$font-size-x-small: 1.2rem;
$font-size-xx-small: 1rem;
$font-size-xxx-small: 0.8rem;

$font-size-regular: 1.6rem;

$font-size-large: 1.8rem;
$font-size-x-large: 2rem;
$font-size-xx-large: 2.4rem;
