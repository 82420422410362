@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.base {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  line-height: 2rem;

  @include respond-below(sm) {
    &.big,
    &.medium {
      font-size: $font-size-regular;
    }
    &.big {
      height: 4.4rem;
      padding: 1.2rem 1.6rem;
    }

    &.medium {
      padding: 0.8rem;
    }

    &.small {
      padding: 0.4rem 0.6rem;
    }

    &.noPadding {
      height: auto;
      padding: 0 !important;
    }
  }

  &.small {
    > *:not(:last-child) {
      margin-right: 0.4rem;
    }
  }

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.children {
  flex-shrink: 0;
}

.leading,
.trailing,
.children {
  display: inline-flex;
}

.loader {
  position: absolute;
  left: calc(50% - 8px);
  top: 25%;
}

.base.isLoading {
  .children,
  .leading,
  .trailing {
    visibility: hidden;
  }
  pointer-events: none;
}

.base::before {
  content: '';
  background-color: var(--kp-color-bg-primary);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: none;
}

.base:focus::before {
  display: none;
}

.base:hover.base span {
  z-index: 1;
}

.base:hover::before {
  opacity: 0.2;

  @include respond-below(sm) {
    opacity: 0;
  }
}

.base:disabled {
  cursor: default;
}

.base:disabled:hover.base:hover:before {
  display: none;
}

.inherit {
  line-height: 2rem;
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.isDisabled {
    text-decoration: none !important;
    cursor: default !important;
    pointer-events: none !important;
  }
}
